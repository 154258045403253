import Enum from '../enum'

/**
 * 枚举类：设置项索引
 * SettingKeyEnum
 */
export default new Enum([{
  key: 'PAGE_CATEGORY_TEMPLATE',
  name: '分类页模板',
  value: 'page_category_template'
}, {
  key: 'POINTS',
  name: '积分设置',
  value: 'points'
}, {
  key: 'RECHARGE',
  name: '充值设置',
  value: 'recharge'
}])
