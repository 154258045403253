<template>
	<div class="my-wallet">
		<div class="my-title clearfix">
			<span class="">我的钱包</span>
		</div>
		<div class="money">
			<div class="money-number">
				<span class="title">账户余额（元）：</span>
				<span class="price">{{ userInfo.balance }}</span>
			</div>
			<div class="money-btn">
				<el-button v-if="setting.is_entrance" class="btn" type="danger" @click="recharge()">充值</el-button>
				<el-button class="btn" type="warning" @click="showBank()">银行汇款</el-button>
			</div>
		</div>
		<div class="tabs-wrapper">
			<el-button class="download-btn" type="primary" size="small" @click="handleDownload()" icon="el-icon-download">导出</el-button>
			<el-tabs v-model="activeName" @tab-click="tabChange" class="tabs-line">
				<el-tab-pane label="充值记录" name="first"></el-tab-pane>
				<el-tab-pane label="账单详情" name="second"></el-tab-pane>
				<el-table v-if="activeName == 'first'" :data="list1.data" stripe style="width: 100%">
					<el-table-column prop="pay_time" label="日期">
					</el-table-column>
					<el-table-column prop="type" :formatter="statusFormat" label="状态">
					</el-table-column>
					<el-table-column prop="actual_money" label="金额(元)">
					</el-table-column>
				</el-table>
				<el-table v-if="activeName == 'second'" :data="list2.data" stripe style="width: 100%">
					<el-table-column prop="create_time" label="日期">
					</el-table-column>
					<el-table-column prop="money" :formatter="moneyFormat" label="金额(元)">
					</el-table-column>
					<el-table-column prop="describe" label="说明">
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div class="paging">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
						:current-page.sync="currentPage" :page-size="100" layout="prev, pager, next, jumper"
						:total="activeName == 'first' ? list1.total : list2.total">
					</el-pagination>
				</div>
			</el-tabs>
			
		</div>
		
		<el-dialog title="充值" :visible.sync="dialogVisible" width="30%" class="recharge-dialog">
			<div class="recharge-qrcode">
				<img :src="qrcode" width="200" />
				<p class="tips">微信扫描上方二维码充值</p>
			</div>
			<!-- <el-form ref="ruleForm" label-width="110px" class="recharge">
        <el-form-item label="">
        </el-form-item>
			</el-form> -->
		</el-dialog>

		<el-dialog title="银行汇款" :visible.sync="bankVisible" width="40%">
			<el-form ref="ruleForm" label-width="100px" class="recharge">
				<el-form-item label="名称:" prop="">
					<p>青岛众创备品网络营销有限公司</p>
				</el-form-item>
				<el-form-item label="税号:" prop="">
					<p>91370212MA3U0T543C</p>
				</el-form-item>
				<el-form-item label="地址:" prop="">
					<p>青岛市崂山区株洲路20号海信创智谷专家公寓2号楼2401室</p>
				</el-form-item>
				<el-form-item label="开户银行:" prop="">
					<p>招商银行青岛国际创新园支行</p>
				</el-form-item>
				<el-form-item label="账号:" prop="">
					<p>5329 1061 4110 506</p>
				</el-form-item>
				<el-form-item label="客服电话:" prop="">
					<p>18753356127 汇款后请电话联系客服！</p>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
    import * as RechargeApi from '@/api/recharge'
    import * as PlanApi from '@/api/recharge/plan'
	import * as UserApi from '@/api/user'
	import * as SettingApi from '@/api/setting'
	import * as OrderApi from '@/api/recharge/order'
	import * as LogApi from '@/api/balance/log'
	import SettingKeyEnum from '@/common/enum/setting/Key'
	import {
	  PayTypeEnum,
	} from '@/common/enum/order'
	export default {
		data() {
			return {
				PayTypeEnum,
				// 会员信息
				userInfo: {},
				// 充值设置
				setting: {},
				// 充值方案列表
				planList: [],
				// 当前选中的套餐id
				selectedPlanId: 0,
				// 自定义金额
				inputValue: '',
				dialogVisible: false,
				currentPage: 1,
				activeName: 'first',
				list1: [],
				list2: [],
				bankVisible: false,
        qrcode: ''
			}
		},
		created() {
			// 获取页面数据
			this.getPageData()
			this.getList()
		},
		methods: {
			getList(){
				let app = this
				if(app.activeName == 'first'){
					OrderApi.list({ page: app.currentPage })
					  .then(result => {
					    // 合并新数据
					    app.list1 = result.data.list
					  })
				}else{
					LogApi.list({ page: app.currentPage })
					  .then(result => {
					    // 合并新数据
					    app.list2 = result.data.list
					  })
				}
			},
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getUserInfo(), app.getSetting(), app.getPlanList()])
			    .then()
			},

			// 获取充值方案列表
			getPlanList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    PlanApi.list()
			      .then(result => {
			        app.planList = result.data.list
			        resolve(app.planList)
			      })
			  })
			},

			// 获取会员信息
			getUserInfo() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    UserApi.info()
			      .then(result => {
			        app.userInfo = result.data.userInfo
			        resolve(app.userInfo)
			      })
			  })
			},

			// 获取充值设置
			getSetting() {
			  const app = this
			  return new Promise((resolve, reject) => {
				  SettingApi.data()
				    .then(result => {
					  app.setting = result.data.setting.recharge
				      resolve(result.data.setting)
				    })
			  })
			},

			// 立即充值
			onSubmit(e) {
			  const app = this
			  // 按钮禁用
			  app.dialogVisible = true
			  // 提交到后端
			  RechargeApi.submit({ planId: app.selectedPlanId, customMoney: app.inputValue, payType: PayTypeEnum.NUONUO.value })
			    .then(result => app.nuoPayment(result.data.payment))
			    .finally(() => app.disabled = false)
			},
			nuoPayment(res){
				let payment = JSON.parse(res)
				console.log(payment.code)
				if(payment.code != "JH200"){
					this.$message.error(payment.describe)
				}else{
					this.$router.push('/settlement/nuonuo?ordertype=recharge&price='+this.inputValue+'&payment='+encodeURIComponent(payment.result.payUtl))
				}
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getList()
			},
			recharge() {
        RechargeApi.getMpPathQrcode().then(res => {
          this.qrcode = 'data:image/jpg;base64,' + res.data.qrcode
        })
				this.dialogVisible = true
			},
			statusFormat(){
				return '充值成功'
			},
			tabChange(tab, event){
				console.log(tab)
				this.activeName = tab.name
				this.currentPage = 1
				this.getList()
			},
			moneyFormat(row){
				return (row.money > 0 ? '+' : '') + row.money
			},
			showBank(){
				this.bankVisible = true
			},
            handleDownload() {
                let app = this

                if (app.activeName == 'first') {
                    OrderApi.list({page: app.currentPage, pagesize: 1000})
                        .then(result => {
                            let tHeader = ['日期', '状态', '金额(元)']
                            let filterVal = ['pay_time', 'recharge_type', 'actual_money']
                            this.exportExcel('充值记录', result.data.list.data, tHeader, filterVal)
                        })
                } else {
                    LogApi.list({page: app.currentPage, pagesize: 1000})
                        .then(result => {
                            let tHeader = ['日期', '金额(元)', '说明']
                            let filterVal = ['create_time', 'money', 'describe']
                            this.exportExcel('账单详情', result.data.list.data, tHeader, filterVal)
                        })
                }
            },
            exportExcel(filename, list, tHeader, filterVal) {
                import('@/vendor/Export2Excel').then(excel => {
                    const data = this.formatJson(filterVal, list)
                    excel.export_json_to_excel({
                        header: tHeader,
                        data,
                        filename: filename,
                        autoWidth: true,
                        bookType: 'xlsx'
                    })
                })
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    if (j === 'recharge_type' && v[j] == 10) {
                        return '充值成功'
                    }
                    if (j === 'sup_code') {
                        return v.goods[j]
                    }
                    if (j === 'grade_goods_price') {
                        if (v.grade_goods_price > 0) {
                            return v.grade_goods_price
                        } else {
                            return v['goods_price']
                        }
                    }
                    return v[j]
                }))
            },
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/css/my-wallet.scss";
</style>
