import request from '@/utils/request'

// api地址
const api = {
  list: 'balance.log/list'
}

// 余额账单明细
export const list = (param) => {
  return request({
    url: api.list,
    method: 'get',
  	params: param,
  })
}
