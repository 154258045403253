import request from '@/utils/request'

// api地址
const api = {
  submit: 'recharge/submit',
    getMpPathQrcode: 'recharge/getMpPathQrcode'
}

// 积分明细列表
export const submit = (data) => {
  return request({
      url: api.submit,
      method: 'post',
	  data
    })
}

export const getMpPathQrcode = (param) => {
    return request({
        url: api.getMpPathQrcode,
        method: 'get',
        params: param
    })
}
